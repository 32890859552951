import { useFilterPath } from '@/hooks/useFilterPath';
import { Button } from '@/components/atoms/Button';
import { Wrapper } from '@/components/atoms/Wrapper';

export const EmptySearchView = () => {
  const { currentPath } = useFilterPath();

  const resetFilters = () => {
    window.location.href = currentPath;
  };

  return (
    <Wrapper className="text-center text-white px-4 my-8 sm:my-12 items-center" fullWidth>
      <h2 className="text-xl font-semibold mb-2">Brak wyników wyszukiwania</h2>
      <p className="text-sm">Może być to spowodowane zbyt dużą ilością użytych filtrów</p>
      <Button
        onClick={resetFilters}
        variant="outlined"
        className="text-sm hover:text-black hover:bg-white w-full sm:w-fit mt-8"
      >
        Resetuj filtry
      </Button>
    </Wrapper>
  );
};
