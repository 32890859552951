import Image from 'next/image';

import { Wrapper } from '@/components/atoms/Wrapper';
import { FeedSortBox } from '@/components/molecules/FeedSortBox';
import { cn } from '@/utils/cn';

import { IFilterPanelProps } from './interfaces';

export const FilterPanel = (props: IFilterPanelProps) => {
  const { isCategoryPage = false, singleTag, singleCategoryData, isInsideFeed } = props;
  const tagName = singleTag?.data?.attributes?.name;
  const categoryName = singleCategoryData?.attributes?.name;
  const categoryDescription = singleCategoryData?.attributes?.description;
  const categoryIcon = singleCategoryData?.attributes?.icon?.data?.attributes?.url;

  const getCurrentTag = () => {
    if (!tagName) return;
    const splittedTagName = tagName?.split('#');
    const currentTagName = splittedTagName?.length > 1 ? splittedTagName[1] : splittedTagName[0];
    // eslint-disable-next-line consistent-return
    return currentTagName;
  };

  const currentTagName = getCurrentTag();

  return (
    <Wrapper className={cn('max-w-[755px]', isInsideFeed && 'md:pb-3 md:gap-4', !isInsideFeed && 'pt-4 md:pt-6')}>
      {isCategoryPage && (
        <Wrapper className="px-3 pt-4 pb-2 regular:px-4 md:px-0">
          <Wrapper className="gap-2 mb-2" row>
            {!!categoryIcon && <Image src={categoryIcon} alt={categoryName} width={36} height={36} />}
            <p className="text-3xl text-white font-semibold ">{categoryName}</p>
          </Wrapper>
          <div
            className="break-word text-white"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: categoryDescription as string }}
          />
        </Wrapper>
      )}
      {singleTag && (
        <Wrapper className="px-4 pt-4 pb-2 md:px-0" row fullWidth>
          <p className="text-3xl font-semibold"># {currentTagName}</p>
        </Wrapper>
      )}
      <FeedSortBox />
    </Wrapper>
  );
};
