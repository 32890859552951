import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRouter } from 'next/router';

import { useFilterPath } from '@/hooks/useFilterPath';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { Wrapper } from '@/components/atoms/Wrapper';
import { TimeIntervalPopover } from '@/components/molecules/TimeIntervalPopover';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { cn } from '@/utils/cn';

export const FeedSortBox = () => {
  const router = useRouter();
  const { currentPath } = useFilterPath();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [isTimeIntervalPopover, setTimeIntervalPopover] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const { query } = router;
  const { top, tag, tagId } = query;
  const isNewButtonSelected = !top;

  const handleTmeIntervalPopover = (value: boolean) => {
    setTimeIntervalPopover(value);
  };

  const getNewButtonLink = () => {
    if (tag) {
      return `${currentPath}?tag=${tag}&tagId=${tagId}`;
    }

    return currentPath;
  };

  useScrollPosition(({ currPos }) => {
    if (isTimeIntervalPopover) {
      setScrollPosition(currPos.y);
      if (scrollPosition && scrollPosition !== currPos.y) {
        setTimeIntervalPopover(false);
      }
    }
  });

  return (
    <Wrapper className="items-center px-3 max-w-laptop gap-2 regular:px-6 md:px-0" row fullWidth>
      <p className="text-xs font-medium text-gray-300">Sortuj według:</p>
      <button
        onClick={() => {
          updateInterstitialActionCount();
          window.location.href = getNewButtonLink();
        }}
        onAuxClick={() => navigateToNewWindow(getNewButtonLink())}
        className={cn(
          '!relative hover:bg-gray-900 hover:ease-in hover:duration-150 text-xs font-medium text-gray-300 rounded-full gap-1.5 px-2 py-1 border border-transparent',
          isNewButtonSelected && 'text-primary-600 border border-primary-900',
        )}
      >
        Nowe
      </button>
      <TimeIntervalPopover showPopover={isTimeIntervalPopover} handlePopover={handleTmeIntervalPopover} />
    </Wrapper>
  );
};
