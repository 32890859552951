import { useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSessionStorage } from 'usehooks-ts';

import { DataItem } from '@/api/posts/interfaces';
import { ITag } from '@/api/tags/interfaces';
import { ICategoryData } from '@/api/categories/interfaces';
import { IUserProfileData, IUserPostStatsData } from '@/api/user/interfaces';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Pagination } from '@/components/molecules/Pagination';
import { EmptySearchView } from '@/components/molecules/EmptySearchView';
import { MediaBox } from '@/components/molecules/MediaBox';
import { UserInfoContainer } from '@/components/molecules/UserInfoContainer';
import { Sidebar } from '@/components/organisms/Sidebar';
import { FilterPanel } from '@/components/organisms/FilterPanel';
import { cn } from '@/utils/cn';

import { PostsFeedTemplate } from '../PostsFeedTemplate';

const DynamicTopPostsMobile = dynamic(
  () => import('@/components/molecules/TopPostsMobile').then((mod) => mod.TopPostsMobile),
  {
    ssr: false,
  },
);

interface IPostsFeed {
  posts?: DataItem[];
  favouritePosts?: DataItem[];
  pageCount: number;
  singleTag?: ITag | null;
  singleCategoryData?: ICategoryData | null;
  categoriesData?: ICategoryData[];
  showFilterPanel: boolean;
  userData?: IUserProfileData;
  userPostStatsData?: IUserPostStatsData;
}

export const PageFeedTemplate = (props: IPostsFeed) => {
  const {
    posts,
    favouritePosts,
    pageCount,
    singleTag,
    categoriesData,
    showFilterPanel,
    userData,
    userPostStatsData,
    singleCategoryData,
  } = props;
  const router = useRouter();
  const { query, pathname } = router;
  const { page } = query;
  const [isSessionStartWithPostPage] = useSessionStorage<number | null>('isSessionStartWithPostPage', null);
  const [isLocalLikesCount, setIsLocalLikesCount] = useState<boolean>(false);
  const isFirstPage = !page || page === '1';
  const isUserPage = !!userData && !!userPostStatsData;
  const isShowTopPostsMobile =
    pathname === '/' && !!favouritePosts?.length && isSessionStartWithPostPage === 1 && isFirstPage;
  const isCategoryOrTag = pathname === '/categories/[categoryId]' || query?.tagId;

  const handleLocalLikesCount = () => {
    setIsLocalLikesCount(true);
  };

  return (
    <Wrapper className="justify-center" fullWidth>
      {isShowTopPostsMobile && (
        <DynamicTopPostsMobile
          favouritePosts={favouritePosts || []}
          isLocalLikesCount={isLocalLikesCount}
          handleLocalLikesCount={handleLocalLikesCount}
        />
      )}
      {showFilterPanel && !isCategoryOrTag && (
        <FilterPanel
          singleTag={singleTag}
          categoriesData={categoriesData || []}
          singleCategoryData={singleCategoryData}
          isCategoryPage={pathname === '/categories/[categoryId]'}
        />
      )}
      <Wrapper
        className={cn('pt-0 md:pt-6 md:justify-between gap-8', isUserPage && 'flex-col md:flex-row')}
        fullWidth
        row
      >
        {isUserPage && <UserInfoContainer userData={userData} userPostStatsData={userPostStatsData} />}
        <Wrapper className="md:gap-3 md:w-10/16 laptop:w-[755px]" fullWidth>
          {showFilterPanel && isCategoryOrTag && (
            <FilterPanel
              isInsideFeed
              singleTag={singleTag}
              categoriesData={categoriesData || []}
              singleCategoryData={singleCategoryData}
              isCategoryPage={pathname === '/categories/[categoryId]'}
            />
          )}
          {!posts?.length && <EmptySearchView />}
          {!!posts?.length && <PostsFeedTemplate posts={posts} />}
          {isFirstPage && (
            <Wrapper className="mt-6 md:hidden px-3 regular:px-6" fullWidth>
              <MediaBox />
            </Wrapper>
          )}
          <Pagination pageCount={pageCount} />
        </Wrapper>
        {!isUserPage && (
          <Sidebar
            categoriesData={categoriesData || []}
            favouritePosts={favouritePosts || []}
            isLocalLikesCount={isLocalLikesCount}
            handleLocalLikesCount={handleLocalLikesCount}
          />
        )}
      </Wrapper>
    </Wrapper>
  );
};
