import { Fragment, useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Popover, Transition } from '@headlessui/react';
import { useOnClickOutside } from 'usehooks-ts';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import clsx from 'clsx';

import { useFilterPath } from '@/hooks/useFilterPath';
import { navigateToNewWindow } from '@/helpers/navigateToNewWindow';
import { Wrapper } from '@/components/atoms/Wrapper';
import { useUpdateInterstitialTriggers } from '@/components/organisms/AdCompanion/hooks/useUpdateInterstitialTriggers';
import { cn } from '@/utils/cn';

interface ITimeIntervalPopover {
  showPopover: boolean;
  handlePopover: (value: boolean) => void;
}

export const TimeIntervalPopover = ({ showPopover, handlePopover }: ITimeIntervalPopover) => {
  const router = useRouter();
  const { currentPath } = useFilterPath();
  const { updateInterstitialActionCount } = useUpdateInterstitialTriggers();
  const [selectedInterval, setSelectedInterval] = useState<string>('');
  const popoverRef = useRef(null);
  const { query, pathname } = router;
  const { top, tag, tagId } = query;
  const isPopoverSelected = showPopover || top;
  const queryString = `${tag ? `&tag=${tag}&tagId=${tagId}` : ''}`;

  const config = [
    {
      id: 0,
      interval: '24 h',
      redirectUrl: `${currentPath}?top=24h${queryString}`,
    },
    {
      id: 1,
      interval: '7 dni',
      redirectUrl: `${currentPath}?top=7d${queryString}`,
    },
    {
      id: 2,
      interval: '30 dni',
      redirectUrl: `${currentPath}?top=30d${queryString}`,
    },
    {
      id: 3,
      interval: 'Zawsze',
      redirectUrl: `${currentPath}?top=allTime${queryString}`,
    },
  ];

  const onPopoverClose = () => {
    handlePopover(false);
  };

  const onTimeIntervalClick = (interval: string, url: string) => {
    ReactGA.event('change_sorting_order', {
      current_sort_order: selectedInterval,
      chosen_sort_order: interval,
      entry_point: pathname,
    });
    updateInterstitialActionCount();
    setSelectedInterval(interval);
    onPopoverClose();
    window.location.href = url;
  };

  useOnClickOutside(popoverRef, onPopoverClose);

  useEffect(() => {
    if (!top) {
      setSelectedInterval('');
      return;
    }
    if (top === '24h') {
      setSelectedInterval('24 h');
      return;
    }
    if (top === '7d') {
      setSelectedInterval('7 dni');
      return;
    }
    if (top === '30d') {
      setSelectedInterval('30 dni');
      return;
    }
    if (top === 'allTime') {
      setSelectedInterval('Zawsze');
    }
  }, [top]);

  return (
    <Popover className="min-w-fit">
      <Popover.Button
        className={cn(
          '!relative hover:bg-gray-900 hover:ease-in hover:duration-150 text-xs font-medium text-gray-300 rounded-full outline-none flex items-center min-w-full gap-1.5 px-2 py-1 border border-transparent',
          isPopoverSelected && 'text-primary-600 border border-primary-900',
        )}
        onClick={() => handlePopover(!showPopover)}
      >
        Top {selectedInterval.toLowerCase()}
        <ChevronDownIcon width={14} strokeWidth={3} className="min-w-[14px]" />
      </Popover.Button>
      <Transition
        as={Fragment}
        show={showPopover}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute z-50 mt-3 px-0 w-160" ref={popoverRef}>
          <Wrapper
            className="border border-gray-800 rounded-lg shadow-lg overflow-hidden bg-gray-950 p-2 gap-0.5"
            fullWidth
          >
            {config.map(({ id, interval, redirectUrl }) => (
              <button
                onAuxClick={() => navigateToNewWindow(redirectUrl)}
                onClick={() => {
                  onTimeIntervalClick(interval, redirectUrl);
                }}
                key={id}
                className={clsx(
                  'text-sm font-semibold hover:bg-gray-900 cursor-pointer rounded py-1.5 px-2 flex',
                  selectedInterval === interval && 'bg-gray-900',
                )}
              >
                {interval}
              </button>
            ))}
          </Wrapper>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
