import { useRouter } from 'next/router';

export const useFilterPath = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const { id: userId, categoryId } = query;

  const getPath = () => {
    if (pathname === '/userProfile/[id]') {
      return `/userProfile/${userId}`;
    }

    if (pathname === '/categories/[categoryId]') {
      return `/categories/${categoryId}`;
    }

    return pathname;
  };

  const currentPath = getPath();

  return { currentPath };
};
