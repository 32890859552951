import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { IUserPostStatsData, IUserProfileData } from '@/api/user/interfaces';
import { useUserCookies } from '@/hooks/useUserCookies';
import { Wrapper } from '@/components/atoms/Wrapper';
import { Button } from '@/components/atoms/Button';
import { Card } from '@/components/atoms/Card';
import { AvatarComponent } from '@/components/atoms/AvatarComponent';
import { useManageProfileContext } from '@/components/organisms/ManageProfileModal/ManageProfileContext';

interface IUserInfoProps {
  userData: IUserProfileData;
  userPostStatsData: IUserPostStatsData;
}

export const UserInfoContainer = ({ userData, userPostStatsData }: IUserInfoProps) => {
  const { id: accountOwnerId, avatar: userAvatar } = useUserCookies();
  const { setManageProfileModal } = useManageProfileContext();
  const router = useRouter();
  const [showManageProfileButton, setShowManageProfileButton] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const { id, username, createdAt, avatar } = userData;
  const userDataAvatar = avatar !== null ? avatar?.formats?.medium?.url : '';
  const { postsCount, postsLikesCount } = userPostStatsData;
  const creationDate = dayjs(createdAt).format('DD.MM.YYYY');
  const { asPath } = router;

  useEffect(() => {
    if (accountOwnerId === id) {
      setShowManageProfileButton(true);
      return;
    }

    setShowManageProfileButton(false);
  }, [accountOwnerId, id]);

  useEffect(() => {
    if (!accountOwnerId) {
      const path = asPath.split('#');
      void router.push(path[0]);
    }
  }, [accountOwnerId, asPath]);

  useEffect(() => {
    if (accountOwnerId === id && userAvatar) {
      setImage(userAvatar);
      return;
    }

    if (accountOwnerId !== id && userDataAvatar) {
      setImage(userDataAvatar);
      return;
    }

    setImage('');
  }, [userAvatar, asPath, accountOwnerId, id, userDataAvatar]);

  return (
    <Wrapper className="w-full md:w-6/16 wideDesktop:w-5/16 p-4 md:p-0">
      <Card className={clsx('border border-gray-800 items-center', '!px-4 !py-6')} fullWidth>
        <AvatarComponent avatar={image} className="w-32 h-32" />
        <Wrapper className="text-center gap-2 my-4">
          <p className="text-xl font-semibold">{username}</p>
          <p className="text-sm text-gray-300">Użytkownik od {creationDate}</p>
        </Wrapper>
        {showManageProfileButton && (
          <Button
            onClick={() => setManageProfileModal(true)}
            variant="outlined"
            className={clsx('w-full max-w-310 hover:opacity-70 text-sm', '!border-gray-600 !py-2 mb-4')}
          >
            Zarządzaj profilem
          </Button>
        )}
        <Wrapper className="gap-8" row>
          <Wrapper className="text-center">
            <p className="text-sm text-primary-600">Wrzuty</p>
            <p className="text-lg font-semibold">{postsCount}</p>
          </Wrapper>
          <Wrapper className="text-center">
            <p className="text-sm text-primary-600">Otrzymane lajki</p>
            <p className="text-lg font-semibold">{postsLikesCount}</p>
          </Wrapper>
        </Wrapper>
      </Card>
    </Wrapper>
  );
};
